<template>
  <div class="constrain part">
    <div class="row-12">
      <div class="md:offset-2 md:col-8">
        <h3 class="mb-4" v-html="payload.titel" />
        <div class="mb-8 text-navy" v-html="payload.beschreibung" />
        <div class="row-12">
          <div class="md:col-12 border-solid border-l-2 border-lightblue pl-4 md:pl-8">
            <ol class="rule-list text-navy" type="A">
              <li v-for="rule in rules" :key="rule.id" class="list-item row-12">
                <span class="inline-block md:col-6">
                  {{ rule.text }}
                </span>
                <span class="font-bold md:font-normal md:offset-1 md:col-5">
                  {{ rule.jahresbeitrag }}
                </span>
              </li>
            </ol>
          </div>
          <!-- <div class="md:offset-1 md:col-5">
            <ul class="list-none text-navy">
              <li v-for="rule in rules" :key="rule.id" class="list-item font-semi-bold">
                {{ rule.jahresbeitrag }}
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
  computed: {
    rules() {
      return this.payload.regeln;
    },
  },
  mounted() {
    // console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>
// .rule-list {
//   margin-left: px(27);
// }

.list-item:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
